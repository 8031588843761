'use client';

import { Fragment } from 'react';

import { useStore } from '@/contexts/StoreContext';

import { CategoryRow } from '@/views/CategoriesView/CategoryRow';
import { GanreListRow } from '@/views/CategoriesView/GanreListRow';

// =================================================================

export const CategoriesView = () => {
  const { toggleCategoryPanel } = useStore(state => state);

  return (
    <Fragment>
      <CategoryRow toggleCategoryPanel={toggleCategoryPanel} />
      <GanreListRow toggleCategoryPanel={toggleCategoryPanel} />
    </Fragment>
  );
};
