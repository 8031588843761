import { memo } from 'react';

import cn from 'classnames';

import CineramaLogoSVG from '@/icons/CineramaLogoSVG';
import { Link } from '@/navigation';
import { ClassNameProps } from '@/types/common.types';

import styles from '@/components/Logo/Logo.module.scss';

// =================================================================

type LogoProps = ClassNameProps & {
  isHomeLink?: boolean;
};

// =================================================================

export const Logo = memo((props: LogoProps) => {
  const { className, isHomeLink = true } = props;

  const classes = cn(styles.logoBase, className);
  const children = <CineramaLogoSVG className={styles.logoIcon} />;

  if (isHomeLink) {
    return (
      <Link href="/" prefetch={false} className={classes}>
        {children}
      </Link>
    );
  }

  return <div className={classes}>{children}</div>;
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  Logo.displayName = 'Logo';
}
