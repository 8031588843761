import { useLocale } from 'next-intl';

import { Locale } from '@/types/common.types';

import { useInfiniteLoader } from '@/hooks/useInfiniteLoader';

import { MovieApi } from '@/api/domains/movie.api';
import { WebGenres } from '@/api/types/home.types';

import { GanreCategoryQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useGanresList = () => {
  const locale = useLocale() as Locale;

  const result = useInfiniteLoader<WebGenres, { title: string }>({
    queryKey: GanreCategoryQueryKeys.ganre(locale),
    queryFn: ({ pageParam = 1 }) => MovieApi.fetchGenres({ page: pageParam, limit: 12 }),
    initialPageParam: 1,
  });

  return result;
};
