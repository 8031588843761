'use client';

import { Fragment } from 'react';

import { PrimaryNav } from '@/layout/DesktopMenu/PrimaryNav';
import { SearchBar } from '@/layout/SearchBar/SearchBar';

import { useStore } from '@/contexts/StoreContext';

export const SelectedMenu = () => {
  const { isSearchBarVisible } = useStore(state => state);

  return <Fragment>{isSearchBarVisible || <PrimaryNav />}</Fragment>;
};
