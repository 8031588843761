'use client';

import { Fragment, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useKey } from 'react-use';

import { isEmpty } from 'lodash';

import RemoveSVG from '@/icons/RemoveSVG';
import SearchSVG from '@/icons/SearchSVG';
import StarSVG from '@/icons/StarSVG';
import { useRouter } from '@/navigation';

import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import { useDebouncedSearch } from '@/layout/SearchBar/hooks/useDebouncedSearch';

import { IconButton } from '@/components/Buttons';
import { Form } from '@/components/Form';
import { List } from '@/components/List';
import { Spinner } from '@/components/Spinner';

import styles from '@/layout/SearchBar/SearchForm.module.scss';
import { SuggestedDropdown } from '@/layout/SearchBar/SuggestedDropdown';

// =================================================================

interface SearchFromProps {
  toggleSearchBar: (isSearchBarVisible: boolean) => void;
  isSearchBarVisible?: boolean;
}

// =================================================================

const popularSuggestions = [
  'Головоломка 2',
  'Мстители: Финал',
  'Интерстеллар',
  'Начала',
  'Острав проклятых',
];

// =================================================================

export const SearchFrom = (props: SearchFromProps) => {
  const { toggleSearchBar, isSearchBarVisible } = props;
  useLockBodyScroll();
  const router = useRouter();

  const [indexOfHighlightedElement, setIndexOfHighlightedElement] = useState<number | null>(null);

  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  });
  const [isFetching, movies] = useDebouncedSearch(methods.watch('search'));

  useKey('Escape', () => {
    toggleSearchBar(false);
    if (document) (document.getElementById('search') as HTMLElement).blur();
  });

  const handleSubmit = () => {
    if (isFetching || isEmpty(movies)) {
      return;
    }

    if (indexOfHighlightedElement && movies && movies[indexOfHighlightedElement]) {
      const { moduleId, id } = movies[indexOfHighlightedElement];

      router.push(`/watch/${moduleId}/${id}`);
    } else {
      router.push(`/search-results?query=${methods.getValues('search')}`);
    }

    toggleSearchBar(false);
    if (document) (document.getElementById('search') as HTMLElement).blur();
  };

  return (
    <Fragment>
      <div className={styles.searchFormWrapper}>
        <FormProvider {...methods}>
          <Form
            isSubmitting={methods.formState.isSubmitting}
            className={styles.searchForm}
            onSubmit={methods.handleSubmit(handleSubmit)}
          >
            <Form.Input
              name="search"
              placeholder={'Поиск'}
              errorClassName={styles.errorMessage}
              type="text"
              icon={SearchSVG}
              onClick={() => toggleSearchBar(true)}
            />
            {isSearchBarVisible && (
              <IconButton
                as="button"
                icon={RemoveSVG}
                iconWidth={20}
                iconHeight={20}
                className={styles.toggler}
                onClick={() => {
                  toggleSearchBar(false);
                  methods.resetField('search');
                }}
              />
            )}
            {isFetching && <Spinner className={styles.spinner} />}
          </Form>
        </FormProvider>
      </div>

      {isSearchBarVisible && (
        <div className={styles.suggestedWrapper}>
          {/* {!isEmpty(popularSuggestions) && !movies && (
            <Fragment>
              <p className={styles.subtitle}>Популярные поиски</p>
              <List as="ul">
                {popularSuggestions.map((suggestion, key) => (
                  <List.Element
                    as={'li'}
                    icon={StarSVG}
                    key={key}
                    onClick={() => methods.setValue('search', suggestion)}
                    className={styles.suggestions}
                  >
                    {suggestion}
                  </List.Element>
                ))}
              </List>
            </Fragment>
          )} */}

          {movies && methods.watch('search') && (
            <SuggestedDropdown
              movies={movies}
              toggleSearchBar={() => toggleSearchBar(false)}
              query={methods.getValues('search')}
              indexOfHighlightedElement={Number(indexOfHighlightedElement)}
              setIndexOfHighlightedElement={setIndexOfHighlightedElement}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};
