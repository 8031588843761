import { ForwardedRef, useEffect } from 'react';

import clsx from 'clsx';

import { breakpoints } from '@/app.config';

import { useHover } from '@/hooks/useHover';

import { MovieList } from '@/api/types/movie.types';

import { Card, CardBody, CardImage, CardTitle } from '@/components/Card';

import styles from '@/layout/SearchBar/SuggestionCard.module.scss';

// =================================================================

interface SuggestionCardProps {
  movie: MovieList;
  onHovered?: (index: number) => void;
  isHighlighted?: boolean;
  index: number;
}

// =================================================================

const CARD_ASPECT_RATIO = 146 / 200;

// =================================================================

export const SuggestionCard = (props: SuggestionCardProps) => {
  const { movie, isHighlighted, onHovered, index } = props;

  const { id, poster, title, moduleId } = movie;

  const { ref, isHovered } = useHover();

  useEffect(() => {
    if (isHovered && typeof onHovered === 'function') {
      onHovered(index);
    }
  }, [index, isHovered, onHovered]);

  return (
    <Card
      ref={ref as ForwardedRef<HTMLDivElement>}
      className={clsx({
        [styles.suggestion]: true,
        [styles.highlighted]: isHighlighted,
      })}
    >
      <CardImage
        as="link"
        href={`/watch/${moduleId}/${id}`}
        src={poster}
        alt={title}
        aspectRatio={CARD_ASPECT_RATIO}
        sizes={`50vw, ${breakpoints.small} 33vw, ${breakpoints.medium} 25vw, ${breakpoints.large} 17vw`}
        imageClassName={styles.movieImage}
        className={styles.movieImage}
        imageWrapperClassName={styles.movieImage}
      >
        <CardBody className={styles.movieCardBody}>
          <CardTitle as="h3" lines={2}>
            {title}
          </CardTitle>
        </CardBody>
      </CardImage>
    </Card>
  );
};
