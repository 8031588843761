import clsx from 'clsx';

import { breakpoints } from '@/app.config';
import { Link } from '@/navigation';
import { ClassNameProps } from '@/types/common.types';

import { Catalog } from '@/api/types/home.types';

import { Card, CardImage, CardTitle } from '@/components/Card';

import styles from '@/views/CategoriesView/CategoryCard.module.scss';

// =================================================================

interface CategoryCardProps extends ClassNameProps {
  category: Catalog;
  onClick?: () => void;
}

// =================================================================

const aspectRatio = 147 / 80;

// =================================================================

export const CategoryCard = (props: CategoryCardProps) => {
  const { category, className, onClick } = props;
  const { title, module_slug } = category;
  return (
    <Link href={module_slug === 'tv' ? '/online-tv' : `/movies/${category.module_id}`}>
      <Card className={clsx(styles.categoryCard, className)} onClick={onClick}>
        <CardImage
          as="div"
          aspectRatio={aspectRatio}
          src={category.image}
          alt={category.title}
          sizes={`62.5vw, ${breakpoints.small} 50vw`}
        />
        <CardTitle lines={1} className={styles.categoryTitle}>
          {title}
        </CardTitle>
      </Card>
    </Link>
  );
};
