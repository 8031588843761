import { Fragment } from 'react';

import { Backdrop } from '@/components/Backdrop';

import styles from '@/layout/SearchBar/SearchBar.module.scss';
import { SearchFrom } from '@/layout/SearchBar/SearchForm';

// =================================================================

interface SearchBarProps {
  toggleSearchBar: (isSearchBarVisible: boolean) => void;
  isSearchBarVisible?: boolean;
}

// =================================================================

export const SearchBar = (props: SearchBarProps) => {
  const { toggleSearchBar, isSearchBarVisible } = props;
  return (
    <Fragment>
      {isSearchBarVisible && (
        <Backdrop className={styles.backdrop} onClick={() => toggleSearchBar(false)} />
      )}

      <div className={styles.searchBar}>
        <SearchFrom toggleSearchBar={toggleSearchBar} isSearchBarVisible={isSearchBarVisible} />
      </div>
    </Fragment>
  );
};
