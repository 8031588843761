'use client';

import { forwardRef } from 'react';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import { ClassNameProps } from '@/types/common.types';

import { Button, ButtonProps } from '@/components/Buttons';
import styles from '@/components/LoaderButton/LoaderButton.module.scss';

// =================================================================

interface LoaderButtonProps
  extends ClassNameProps,
    Partial<Pick<ButtonProps, 'variant' | 'shape'>> {
  isLoading: boolean;
  onClick: () => void;
}

// =================================================================

export const LoaderButton = forwardRef<HTMLButtonElement, LoaderButtonProps>((props, ref) => {
  const { isLoading, onClick, className, variant = 'primary-gradient', shape } = props;

  const t = useTranslations('common');

  return (
    <div className={styles.loaderButtonWrapper}>
      <Button
        as="button"
        ref={ref}
        variant={variant}
        shape={shape}
        size="lg"
        disabled={isLoading}
        onClick={onClick}
        className={clsx(styles.loaderButton, className)}
      >
        {isLoading ? `${t('loading')}...` : t('showMore')}
      </Button>
    </div>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  LoaderButton.displayName = 'LoaderButton';
}
