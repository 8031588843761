'use client';

import { Fragment, ReactNode, useState } from 'react';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import { ClassNameProps } from '@/types/common.types';

import { useInvalidateActiveSubscriptions } from '@/views/UserProfileViews/UserSubscriptionsView/ActiveSubscriptionsView/useActiveSubscriptions';

import { Modal } from '@/components/Modal';
import styles from '@/components/PromoCodeModal/PromoCodeModal.module.scss';

import { PromoCodeFormView } from '@/views/UserProfileViews/UserSubscriptionsView/PromoCodeView/PromoCodeForm';

export const PromoCodeModal = (props: Partial<ClassNameProps> & { icon?: ReactNode }) => {
  const { className, icon } = props;
  const [open, setOpen] = useState<boolean>(false);
  const invalidateActiveSubscriptions = useInvalidateActiveSubscriptions();

  const t = useTranslations('layout');

  const onClose = () => setOpen(false);

  const onOpen = () => setOpen(true);

  return (
    <Fragment>
      <button className={clsx(styles.navLink, className)} onClick={onOpen}>
        {icon}
        {t('header.navigation.promocode')}
      </button>

      <Modal size="sm" isOpen={open} onClose={onClose} className={styles.alertModal}>
        <Modal.Header onClose={onClose}>Промокод</Modal.Header>
        <Modal.Body>
          <PromoCodeFormView
            handleOnSuccessFn={() => {
              invalidateActiveSubscriptions();
              onClose();
            }}
            isModalVariant
          />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
