'use client';

import { Col, Row } from 'react-bootstrap';

import { useTranslations } from 'next-intl';

import { ClassNameProps } from '@/types/common.types';

import { useGanresList } from '@/views/CategoriesView/useGenresList';

import { LoaderButton } from '@/components/LoaderButton';
import { SectionHeading } from '@/components/SectionHeading';

import { HomeGenreCard } from '@/views/HomeView/HomeGenres/HomeGenreCard';
import { HomeGenreCardSkelton } from '@/views/HomeView/HomeGenres/HomeGenreCardSkelton';

// =================================================================

interface GanreListRowProps extends ClassNameProps {
  toggleCategoryPanel: (isCategoryVisible: boolean) => void;
}

// =================================================================

export const GanreListRow = (props: GanreListRowProps) => {
  const { className, toggleCategoryPanel } = props;

  const t = useTranslations('page.home');

  const { data, handleButtonClick, hasNextPage, isLoading, ref, status } = useGanresList();

  return (
    <div className={className}>
      <SectionHeading as="h1">{t('genres')}</SectionHeading>
      <Row as={'ul'} className="g-3 py-3">
        {status === 'pending' && !data
          ? Array(10)
              .fill(null)
              .map((_, index) => (
                <Col xs={6} sm={4} md={3} lg={3} xl={2} xxl={2} as={'li'} key={index}>
                  <HomeGenreCardSkelton />
                </Col>
              ))
          : data?.list.map(genre => (
              <Col xs={6} sm={4} md={3} lg={3} xl={2} xxl={2} as={'li'} key={genre.id}>
                <HomeGenreCard
                  key={genre.id}
                  variant="homeGenreCard"
                  genre={genre}
                  onClick={() => toggleCategoryPanel(false)}
                />
              </Col>
            ))}

        {hasNextPage && (
          <LoaderButton
            ref={ref}
            isLoading={isLoading}
            onClick={handleButtonClick}
            shape="round"
            variant="secondary"
          />
        )}
      </Row>
    </div>
  );
};
