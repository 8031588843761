import { useQuery } from '@tanstack/react-query';

import { useLocale } from 'next-intl';

import { Locale } from '@/types/common.types';

import { HomeApi } from '@/api/domains/home.api';

import { HomeQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useCategories = () => {
  const locale = useLocale() as Locale;

  const { data, isLoading } = useQuery({
    queryKey: HomeQueryKeys.homeCatalogs(locale),
    queryFn: () => HomeApi.fetchHomeCatalogs(),
    select: data => data.sort((a, b) => a.position_order - b.position_order),
  });

  return { categories: data, isLoading };
};
