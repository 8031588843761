import type { SVGProps } from '@/types/common.types';

const EnSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_830_19777)">
        <path
          d="M18.75 0H5.25C2.3505 0 0 2.3505 0 5.25V18.75C0 21.6495 2.3505 24 5.25 24H18.75C21.6495 24 24 21.6495 24 18.75V5.25C24 2.3505 21.6495 0 18.75 0Z"
          fill="#F5F5F5"
        />
        <path
          d="M24 10.7586H13.2414V0H10.7586V10.7586H0V13.2414H10.7586V24H13.2414V13.2414H24V10.7586Z"
          fill="#FF4B55"
        />
      </g>
      <defs>
        <clipPath id="clip0_830_19777">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EnSVG;
