'use client';

import { useMemo } from 'react';
import { useEffect, useState } from 'react';

import NotificationsSVG from '@/icons/NotificationsSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { customLocalStorage } from '@/helpers/storage.helpers';

import { USER_ACCESS_TOKEN } from '@/constants/user-credentials.constants';

import { Button } from '@/components/Buttons';
import { IconButton } from '@/components/Buttons';

import { LoginButton } from '@/layout/DesktopMenu/LoginButton';
import styles from '@/layout/DesktopMenu/SecondaryNav.module.scss';
import { LanguageDropdown } from '@/layout/LanguageDropdown';
import { UserDropdownMenu } from '@/layout/UserDropdownMenu';

export const SecondaryNav = () => {
  const { userAccessToken, userCredentials } = useVerification();
  const [hasNotAccessToken, setHasNotAccessToken] = useState<boolean>(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasNotAccessToken(Boolean(customLocalStorage.getItem(USER_ACCESS_TOKEN)));
    }
  }, [userAccessToken]);

  return (
    <div className={styles.secondaryNav}>
      <IconButton
        as="link"
        href="/notifications"
        icon={NotificationsSVG}
        iconWidth={25}
        iconHeight={25}
        className={styles.notificationsIcon}
      />
      <LanguageDropdown />

      {!hasNotAccessToken && (
        <Button
          as="link"
          href={'/subscriptions'}
          variant="primary"
          size={'md'}
          className={styles.navButton}
        >
          <span>30 дней за 900 сум</span>
        </Button>
      )}
      {userCredentials?.discount && (
        <Button
          as="link"
          href={'/subscriptions'}
          variant="primary"
          size={'md'}
          className={styles.navButton}
        >
          <span>30 дней за {userCredentials.discount_price} сум</span>
        </Button>
      )}
      {userAccessToken && <UserDropdownMenu />}
      {!userAccessToken && <LoginButton />}
    </div>
  );
};
