import type { SVGProps } from '@/types/common.types';

const NotificationsSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="currentColor"
      {...props}
    >
      <path
        opacity="0.3"
        d="M17.4316 9.22374C17.0482 9.22374 16.7371 8.91272 16.7371 8.52934C16.7371 6.58143 15.9788 4.75088 14.6017 3.37301C14.3302 3.10153 14.3302 2.66272 14.6017 2.39111C14.8732 2.11963 15.3121 2.11963 15.5836 2.39111C17.2233 4.03067 18.126 6.21064 18.126 8.52934C18.126 8.91272 17.8149 9.22374 17.4316 9.22374Z"
        fill="currentColor"
      />
      <path
        opacity="0.3"
        d="M3.54255 9.22374C3.1593 9.22374 2.84814 8.91272 2.84814 8.52934C2.84814 6.21064 3.75096 4.03067 5.39051 2.39111C5.66199 2.11963 6.10094 2.11963 6.37242 2.39111C6.64402 2.66272 6.64402 3.10153 6.37242 3.37301C4.99531 4.75012 4.23708 6.58143 4.23708 8.52934C4.23708 8.91272 3.92592 9.22374 3.54255 9.22374Z"
        fill="currentColor"
      />
      <path
        d="M17.0002 13.4118C15.9502 12.5243 15.3481 11.227 15.3481 9.85271V7.91663C15.3481 5.47293 13.5328 3.45 11.1814 3.11112V2.36103C11.1814 1.97702 10.8703 1.66663 10.487 1.66663C10.1036 1.66663 9.79248 1.97702 9.79248 2.36103V3.11112C7.44047 3.45 5.62581 5.47293 5.62581 7.91663V9.85271C5.62581 11.227 5.02373 12.5243 3.96756 13.4173C3.69736 13.6486 3.54248 13.9847 3.54248 14.3402C3.54248 15.0104 4.0876 15.5556 4.75784 15.5556H16.2162C16.8863 15.5556 17.4314 15.0104 17.4314 14.3402C17.4314 13.9847 17.2765 13.6486 17.0002 13.4118Z"
        fill="currentColor"
      />
      <path
        opacity="0.3"
        d="M10.4869 18.3333C11.7445 18.3333 12.7966 17.4368 13.0383 16.25H7.93555C8.17714 17.4368 9.22924 18.3333 10.4869 18.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default NotificationsSVG;
