import { SearchCategory } from './SearchCategory';

import { Logo } from '@/components/Logo';

import styles from '@/layout/DesktopMenu/DesktopMenu.module.scss';
import { SecondaryNav } from '@/layout/DesktopMenu/SecondaryNav';
import { SelectedMenu } from '@/layout/DesktopMenu/SelectedMenu';

export const DesktopMenu = () => {
  return (
    <nav className={styles.desktopMenu}>
      <Logo className={styles.desktopHeaderLogo} />
      <SearchCategory />

      <div className={styles.desktopRightNav}>
        <SelectedMenu />
        <SecondaryNav />
      </div>
    </nav>
  );
};
