import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cnnew/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cnnew/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cnnew/node_modules/react-bootstrap/esm/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["PromoCodeModal"] */ "/var/www/cnnew/src/components/PromoCodeModal/PromoCodeModal.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/contexts/NetworkContext/NetworkContext.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/contexts/NotificationContext/NotificationContext.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/contexts/QueryContext/QueryContext.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/contexts/StoreContext/StoreContext.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/contexts/VerificationContext/VerificationContext.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MainFooter/FooterApps/FooterApps.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MainFooter/FooterBottom.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MainFooter/FooterBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MainFooter/FooterContacts/ContactCard.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MainFooter/FooterContacts/FooterContacts.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MainFooter/FooterNavbar/FooterNavbar.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MainFooter/FooterNavbar/NavColumn.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MainFooter/FooterSocials/FooterSocials.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MainFooter/FooterSocials/SocialCard.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MainFooter/MainFooter.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MainHeader/MainHeader.tsx");
