'use client';

import { Container } from 'react-bootstrap';
import { useMedia } from 'react-use';

import clsx from 'clsx';

import { breakpoints } from '@/app.config';

import { DesktopMenu } from '@/layout/DesktopMenu';
import styles from '@/layout/MainHeader/MainHeader.module.scss';
import { MobileMenu } from '@/layout/MobileMenu';

export const MainHeader = () => {
  const medium = useMedia(breakpoints.medium, false);

  return (
    <header
      className={clsx(styles.mainHeader, {
        [styles.mobile]: !medium,
      })}
    >
      <Container>
        <DesktopMenu />
        <MobileMenu />
      </Container>
    </header>
  );
};
