'use client';

import { Fragment } from 'react';
import { isMobile } from 'react-device-detect';

import { MobileNavigation } from './MobileNavigation';

import NotificationsSVG from '@/icons/NotificationsSVG';

import { IconButton } from '@/components/Buttons';
import { Logo } from '@/components/Logo';

import { LanguageDropdown } from '@/layout/LanguageDropdown';
import styles from '@/layout/MobileMenu/MobileMenu.module.scss';
import { SidebarToggler } from '@/layout/MobileMenu/SidebarToggler';

export const MobileMenu = () => {
  return (
    <Fragment>
      <nav className={styles.mobileMenu}>
        {/* <SidebarToggler /> */}
        <Logo className={styles.mobileHeaderLogo} />
        <div className={styles.rightside}>
          <LanguageDropdown />
          <IconButton
            as="link"
            href="/notifications"
            icon={NotificationsSVG}
            iconWidth={25}
            iconHeight={25}
            className={styles.notificationsIcon}
          />
        </div>
      </nav>
      <MobileNavigation />
    </Fragment>
  );
};
