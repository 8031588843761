'use client';

import { useTranslations } from 'next-intl';

import { navigationList } from '@/app.config';
import DiscountSVG from '@/icons/DiscountSVG';
import FavoriteSecondarySVG from '@/icons/FavoriteSecondarySVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { NavLink } from '@/components/NavLink';
import { PromoCodeModal } from '@/components/PromoCodeModal/PromoCodeModal';

import styles from '@/layout/DesktopMenu/PrimaryNav.module.scss';

export const PrimaryNav = () => {
  const t = useTranslations('layout');

  const { userAccessToken } = useVerification();

  return (
    <ul className={styles.primaryNav}>
      {userAccessToken && (
        <li>
          <NavLink
            href={'/users-history/favorites'}
            className={styles.navLink}
            activeClassName={styles.active}
          >
            <span className={styles.activeIcon}>
              <FavoriteSecondarySVG />
            </span>
            {t('header.navigation.my')}
          </NavLink>
        </li>
      )}
      {navigationList.map((item, index) => (
        <li key={index}>
          <NavLink href={item.href} className={styles.navLink} activeClassName={styles.active}>
            <span className={styles.activeIcon}>{item.icon}</span>
            {t(item.title)}
          </NavLink>
        </li>
      ))}

      <PromoCodeModal icon={<DiscountSVG height={20} width={20} />} />
    </ul>
  );
};
