import type { SVGProps } from '@/types/common.types';

const CineramaLogoSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="177"
      height="28"
      viewBox="0 0 177 28"
      fill="none"
      {...props}
    >
      <path
        d="M0 17.4703C0 15.448 0.244315 13.766 0.732944 12.4243C1.23842 11.0631 1.95452 9.98387 2.88123 9.18661C3.82479 8.36991 4.96212 7.79627 6.29321 7.4657C7.64116 7.11568 9.15759 6.94067 10.8425 6.94067C12.4095 6.94067 13.8248 7.07679 15.0885 7.34903C16.3691 7.60182 17.4727 8.03934 18.3994 8.66159C19.343 9.26439 20.0928 10.0811 20.6488 11.1117C21.2048 12.1423 21.5334 13.416 21.6345 14.9327H15.038C14.9537 14.466 14.8021 14.0868 14.5831 13.7952C14.364 13.484 14.0692 13.2507 13.6985 13.0951C13.3446 12.9396 12.9234 12.8326 12.4348 12.7743C11.963 12.7159 11.4322 12.6868 10.8425 12.6868C10.2191 12.6868 9.64622 12.7257 9.12389 12.8034C8.60157 12.8812 8.15506 13.0465 7.78437 13.2993C7.41369 13.5326 7.11883 13.8827 6.89979 14.3494C6.6976 14.7966 6.5965 15.4091 6.5965 16.1869V18.8704C6.5965 19.6288 6.6976 20.2316 6.89979 20.6788C7.10198 21.1261 7.38842 21.4664 7.7591 21.6997C8.12979 21.933 8.57629 22.0886 9.09862 22.1664C9.62095 22.2247 10.2022 22.2539 10.8425 22.2539C11.9546 22.2539 12.8813 22.1178 13.6227 21.8455C14.3809 21.5539 14.8611 20.9608 15.0633 20.0663H21.6598C21.5418 21.5247 21.2048 22.7595 20.6488 23.7706C20.0928 24.7818 19.343 25.5985 18.3994 26.2207C17.4727 26.843 16.3691 27.2902 15.0885 27.5625C13.8248 27.8542 12.4095 28 10.8425 28C9.15759 28 7.64116 27.8444 6.29321 27.5333C4.96212 27.2027 3.82479 26.6388 2.88123 25.8416C1.95452 25.0249 1.23842 23.9456 0.732944 22.6039C0.244315 21.2427 0 19.5315 0 17.4703Z"
        fill="white"
      />
      <path d="M30.4284 7.40736V27.5333H23.9078V7.40736H30.4284Z" fill="white" />
      <path
        d="M39.4483 15.4869V27.5333H33.5089V7.40736H42.279L48.5722 19.4538V7.40736H54.5116V27.5333H45.7415L39.4483 15.4869Z"
        fill="white"
      />
      <path
        d="M74.7844 7.40736V12.9201H64.1188V14.5827H72.1307V19.8913H64.1188V22.0205H74.9108V27.5333H57.5981V7.40736H74.7844Z"
        fill="white"
      />
      <path
        d="M117.397 7.40723L124.65 27.5332H117.902L116.664 24.033H108.45L107.237 27.5332H101.171L108.475 7.40723H117.397ZM110.345 18.6953H114.819L112.595 12.3075L110.345 18.6953Z"
        fill="#FFFF4D"
      />
      <path
        d="M132.174 14.5534V27.5332H126.209V7.40723H136.369L139.276 19.1911L142.157 7.40723H151.963V27.5332H145.468V14.2617L142.233 27.5332H135.383L132.174 14.5534Z"
        fill="#FFFF4D"
      />
      <path
        d="M169.746 7.40723L177 27.5332H170.252L169.013 24.033H160.799L159.586 27.5332H153.521L160.825 7.40723H169.746ZM162.695 18.6953H167.168L164.944 12.3075L162.695 18.6953Z"
        fill="#FFFF4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.7312 14.1174V27.5333H84.8795V21.2234H89.3403L92.3049 27.5333H100.118L96.6548 20.162C97.6523 19.5919 98.4188 18.8154 98.9545 17.8326C99.5086 16.8301 99.7857 15.5917 99.7857 14.1174H77.7312Z"
        fill="#FFFF4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.9935 2.84321L81.4999 4.30009L89.6294 9.29187L95.123 7.835L86.9935 2.84321ZM92.5734 1.36345L99.1787 5.41929L97.7148 0L92.5734 1.36345ZM84.0495 10.7716L75.9754 5.81391L77.7649 12.4383L84.0495 10.7716Z"
        fill="white"
      />
    </svg>
  );
};

export default CineramaLogoSVG;
