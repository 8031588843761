import type { SVGProps } from '@/types/common.types';

const DiscountSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      {...props}
    >
      <path
        d="M6.87839 1.21526C7.81278 0.418971 9.18708 0.418971 10.1215 1.21525L10.6903 1.70002C11.0907 2.04125 11.5886 2.24746 12.113 2.28931L12.858 2.34877C14.0818 2.44642 15.0536 3.4182 15.1512 4.64197L15.2107 5.38699C15.2525 5.91141 15.4587 6.40926 15.8 6.80966L16.2847 7.37851C17.081 8.3129 17.081 9.6872 16.2847 10.6216L15.8 11.1904C15.4587 11.5909 15.2525 12.0887 15.2107 12.6131L15.1512 13.3581C15.0536 14.5819 14.0818 15.5537 12.858 15.6513L12.113 15.7108C11.5886 15.7526 11.0907 15.9589 10.6903 16.3001L10.1215 16.7848C9.18708 17.5811 7.81278 17.5811 6.87839 16.7848L6.30954 16.3001C5.90913 15.9589 5.41128 15.7526 4.88687 15.7108L4.14185 15.6513C2.91808 15.5537 1.9463 14.5819 1.84864 13.3581L1.78919 12.6131C1.74734 12.0887 1.54113 11.5909 1.1999 11.1904L0.715133 10.6216C-0.0811511 9.6872 -0.0811511 8.3129 0.715133 7.37851L1.1999 6.80966C1.54113 6.40926 1.74734 5.91141 1.78919 5.38699L1.84864 4.64197C1.9463 3.4182 2.91808 2.44642 4.14185 2.34877L4.88687 2.28931C5.41128 2.24746 5.90913 2.04125 6.30954 1.70002L6.87839 1.21526Z"
        fill="currentColor"
      />
      <path
        d="M6.83317 6.50008C6.83317 6.96032 6.46007 7.33341 5.99984 7.33341C5.5396 7.33341 5.1665 6.96032 5.1665 6.50008C5.1665 6.03984 5.5396 5.66675 5.99984 5.66675C6.46007 5.66675 6.83317 6.03984 6.83317 6.50008Z"
        fill="#26262A"
      />
      <path
        d="M11.8332 11.5001C11.8332 11.9603 11.4601 12.3334 10.9998 12.3334C10.5396 12.3334 10.1665 11.9603 10.1665 11.5001C10.1665 11.0398 10.5396 10.6667 10.9998 10.6667C11.4601 10.6667 11.8332 11.0398 11.8332 11.5001Z"
        fill="#26262A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4419 6.05806C11.686 6.30214 11.686 6.69786 11.4419 6.94194L6.44194 11.9419C6.19786 12.186 5.80214 12.186 5.55806 11.9419C5.31398 11.6979 5.31398 11.3021 5.55806 11.0581L10.5581 6.05806C10.8021 5.81398 11.1979 5.81398 11.4419 6.05806Z"
        fill="#26262A"
      />
    </svg>
  );
};

export default DiscountSVG;
