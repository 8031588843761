import type { SVGProps } from '@/types/common.types';

const UserProfielSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
        fill="currentColor"
      />
      <path
        d="M14.8333 13.6665H9.83333C8.28678 13.6683 6.80407 14.2834 5.71049 15.377C4.61691 16.4706 4.00176 17.9533 4 19.4998V21.1665C4 21.3875 4.0878 21.5995 4.24408 21.7558C4.40036 21.912 4.61232 21.9998 4.83333 21.9998H19.8333C20.0543 21.9998 20.2663 21.912 20.4226 21.7558C20.5789 21.5995 20.6667 21.3875 20.6667 21.1665V19.4998C20.6649 17.9533 20.0498 16.4706 18.9562 15.377C17.8626 14.2834 16.3799 13.6683 14.8333 13.6665Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UserProfielSVG;
