'use client';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useLocale } from 'next-intl';

import { Locale } from '@/types/common.types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { UserApi } from '@/api/domains/user-api';

import { ProfileQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useActiveSubscriptions = () => {
  const locale = useLocale() as Locale;
  const { userAccessToken } = useVerification();

  const {
    data = [],
    isLoading,
    status,
  } = useQuery({
    queryKey: ProfileQueryKeys.activeSubscriptions(locale),
    queryFn: UserApi.fetchActiveSubscriptions,
    staleTime: 0,
    enabled: Boolean(userAccessToken),
  });

  return { subscriptions: data, isLoading, status };
};

export const useInvalidateActiveSubscriptions = () => {
  const queryClient = useQueryClient();
  const locale = useLocale() as Locale;

  return () => {
    queryClient.invalidateQueries({
      queryKey: ProfileQueryKeys.activeSubscriptions(locale),
    });
  };
};
