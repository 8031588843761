/* eslint-disable @next/next/no-img-element */
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import clsx from 'clsx';

import { ClassNameProps } from '@/types/common.types';

import { CardTitle } from '@/components/Card';

import styles from '@/views/CategoriesView/CategoryCard.module.scss';

// =================================================================

const aspectRatio = 147 / 80;

// =================================================================

interface CategoryCardSkeltonProps extends ClassNameProps {
  variant: 'image' | 'icon';
}

export const CategoryCardSkelton = (props: CategoryCardSkeltonProps) => {
  const { className } = props;
  return (
    <div className={clsx(styles.categoryCard, styles.isSkelton)}>
      <div
        style={{ paddingBottom: `${100 / aspectRatio}%` }}
        className={clsx(styles.skeltonImage, className)}
      >
        <img src="/assets/images/movie-card-placeholder.png" alt="movie-card-skelton" />
      </div>

      <CardTitle className={styles.categoryTitle}>
        <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={120}>
          <Skeleton />
        </SkeletonTheme>
      </CardTitle>
    </div>
  );
};
