'use client';

import { SearchBar } from '../SearchBar/SearchBar';
import { CategoryToggler } from './CategoryPanel';

import styles from '@/layout/DesktopMenu/SearchCategory.module.scss';

import { useStore } from '@/contexts/StoreContext';

export const SearchCategory = () => {
  const { isSearchBarVisible, toggleSearchBar } = useStore(state => state);

  return (
    <div className={styles.searchCategoryContainer}>
      <CategoryToggler />
      <SearchBar toggleSearchBar={toggleSearchBar} isSearchBarVisible={isSearchBarVisible} />
    </div>
  );
};
