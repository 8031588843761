import { forwardRef, type HTMLAttributes } from 'react';

import cn from 'classnames';

import styles from '@/components/Backdrop/Backdrop.module.scss';

// =================================================================

type BackdropProps = HTMLAttributes<HTMLDivElement>;

// =================================================================

export const Backdrop = forwardRef<HTMLDivElement, BackdropProps>((props, ref) => {
  const { className, ...rest } = props;
  return <div ref={ref} className={cn(styles.backdropBase, className)} {...rest} />;
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  Backdrop.displayName = 'Backdrop';
}
